import { Link } from "gatsby";
import React, { Component } from "react";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
  }

  render() {
    const { data, header } = this.props;
    const { menu } = this.state;
    return (
      <nav role="navigation" className={`site-header ${menu ? "active" : ""}`}>
        <div className="container">
          <div className="header-main">
            <div className="logo">
              <Link to="/">
                {data.logo.file.url ? (
                  <img src={data.logo.file.url} alt={data.siteName} />
                ) : (
                  <span>{data.siteName}</span>
                )}
              </Link>
            </div>
            <div
              className="responsive-menu"
              onClick={() => {
                this.setState({
                  menu: !menu,
                });
              }}
            >
              <span></span>
            </div>
            {header === "home" ? (
              <div className="menu">
                <ul
                  onClick={() => {
                    this.setState({
                      menu: false,
                    });
                  }}
                >
                  {data.menus
                    .filter((item) => item === "About")
                    .map((t) => {
                      return (
                        <li key="About">
                          <Link to={`/#About`}>About</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter((item) => item === "Service")
                    .map((t) => {
                      return (
                        <li key="Service">
                          <Link to={`/#Service`}>Service</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter((item) => item === "Products")
                    .map((t) => {
                      return (
                        <li key="Products">
                          <Link to={`/#Products`}>Products</Link>
                        </li>
                      );
                    })}

                  {data.menus
                    .filter((item) => item === "Testimonials")
                    .map((t) => {
                      return (
                        <li key="Testimonials">
                          <Link to={`/#Testimonials`}>Testimonials</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter((item) => item === "Photos")
                    .map((t) => {
                      return (
                        <li key="Photos">
                          <Link to={`/#Photos`}>Photos</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter((item) => item === "Contact")
                    .map((t) => {
                      return (
                        <li key="Contact">
                          <Link to={`/#Contact`}>Contact</Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            ) : (
              <div className="menu">
                <ul
                  onClick={() => {
                    this.setState({
                      menu: false,
                    });
                  }}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  {data.menus
                    .filter((item) => item === "Products")
                    .map((t) => {
                      return (
                        <li key="Products">
                          <Link to={`/products`}>Products</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter((item) => item === "Photos")
                    .map((t) => {
                      return (
                        <li key="photos">
                          <Link to="/photos">Photos</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter((item) => item === "Contact")
                    .map((t) => {
                      return (
                        <li key="Contact">
                          <Link to={`/#Contact`}>Contact</Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>
    );
  }
}
