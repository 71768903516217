import React, { Component } from "react";
import { Link } from "gatsby";
export default class footer extends Component {
  render() {
    const { data, data2 } = this.props;
    return (
      <div className="site-footer" id="footer">
        <footer className="container my-md-5 pt-md-5 border-top">
          <div className="row py-4 border-bottom align-items-center">
            <div className="col-12 col-md-3">
              <h5 style={{ fontWeight: 600 }}>Navigate</h5>
              <ul className="list-unstyled text-small">
                {data.menus.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={"#" + item}>{item}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-12 col-md-6  border-right border-left h-100 ">
              <Link to="/">
                {data.logo.file.url ? (
                  <img
                    src={data.logo.file.url}
                    alt={data.siteName}
                    style={{ width: "300px" }}
                  />
                ) : (
                  <span>{data.siteName}</span>
                )}
              </Link>
            </div>
            <div className="col-12 col-md-3">
              <h5 style={{ fontWeight: 600 }}>Contact</h5>
              <ul className="list-unstyled text-small">
                {/* <li>
                  <span className="text-dark">{data2.phone}</span>
                </li> */}
                <li>
                  <a
                    href={`mailto:${data2.email}`}
                    className="text-dark"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data2.email}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="text-center py-4">
            <p className="text-dark">
              © Copyright{" "}
              <span style={{ color: "#000" }} className="font-weight-bold ">
                {data.siteName}
              </span>{" "}
              {new Date().getFullYear()}. All Rights Reserved.
            </p>
          </div>
        </footer>
      </div>
    );
  }
}
